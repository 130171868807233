import config from '../../package.json';
const { REACT_APP_ENV,GATSBY_ACTIVE_ENV } = process.env;
const appId = '003'; // 商家中心固定值
// const ua = navigator.userAgent.toLowerCase();

const path =
GATSBY_ACTIVE_ENV === 'prod'
    ? ''
    : 'http://cs.lidebiji.com:33100/fpm/reportClick';

const sendTick = (key1 = '', key2 = '', values: any) => {
  console.log('上报数据', key1, key2, values);
  return fetch('http://cs.lidebiji.com:33100/fpm/reportClick', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      key1,
      key2,
      values: JSON.stringify(values) || '',
      appId: appId,
      clientIP: '',
      machineId: '',
      pageUrl: window.location.href || '',
      ver: config.version || '',
      userAgent: '',
      userId: localStorage.getItem('LDMC_USER_ID') || '',
    }),
  });
};

export default sendTick;
