
import React from "react";
import { BuryingPointFc } from "../../../components/buryingPoint";


const ViewMorePcFc: React.FC = () => {

    const viewMore = {
        color:'#666',
        textDecoration:'none',
    }

    return (
        <div className="row viewMore">
            <div className="col phone-more justify-between" >
            
                <a onClick={()=>BuryingPointFc('VO','VO00300100300301',{action:'点击查看更多创作者按钮'})} style={{visibility:"hidden"}}>查看更多</a>
                <a onClick={()=>BuryingPointFc('VO','VO00300100300301',{action:'点击查看更多创作者按钮'})} style={{visibility:"hidden"}} className="pointer"  data-bs-toggle="modal" data-bs-target="#showPcMoreModal" style={viewMore}>查看更多</a>
                <a onClick={()=>BuryingPointFc('VO','VO00300100300401',{action:'点击成为创作者按钮'})} style={{visibility:"hidden"}} className="pointer color-fc"  data-bs-toggle="modal" data-bs-target="#showPcCreatorModal" style={viewMore}>成为创作者</a>
            </div>
        </div>
    )
}

export default ViewMorePcFc;